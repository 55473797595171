<template>
  <div>
    <div class="mt-4" v-for="(elem, index) in dashboard" :key="index">
      <div class="card-title">
        <h1>{{ elem.title }}</h1>
      </div>
      <el-row class="card-item">
        <el-col
          :xs="12"
          :sm="12"
          :md="6"
          v-for="(item, key) in elem.items"
          :key="key"
        >
          <!-- 如果此 item 是沒有任何角色限制 -->
          <div class="item">
            <el-card shadow="hover">
              <div class="card-body">
                <!-- 如果此 item 是 Line Login -->
                <div
                  v-if="item.name === 'greet.line_login'"
                  style="height:100%"
                >
                  <a :href="lineLoginAuthUrl" class="card-content">
                    <div style="font-size: 100px">
                      <i class="fab" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>{{ $t(item.name) }}</span>
                    </div>
                  </a>
                </div>
                <!-- 如果此 item 不是 Line Login -->
                <div v-else style="height:100%">
                  <router-link
                    v-if="item.routerName"
                    class="card-content"
                    :to="{
                      name: item.routerName,
                      query: item.query ? item.query : {}
                    }"
                  >
                    <div class="font-icon">
                      <i class="fas" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>
                        {{ $t(item.name) }}
                      </span>
                    </div>
                  </router-link>
                  <a v-else class="card-content" :href="item.path">
                    <div class="font-icon">
                      <i class="fas" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>
                        {{ $t(item.name) }}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import lineLoginApis from "@/apis/lineLogin";
/*eslint-enable */

import _ from "lodash";

export default {
  metaInfo() {
    return {
      title: "Home - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      isBindLineLogin: true,
      lineLoginAuthUrl: null,
      dashboard: [
        {
          title: "Tests",
          items: [
            // {
            //   // SAT
            //   name: "greet.SAT",
            //   path: `https://sat.ivy-way.com?token=${this.token}`,
            //   auths: ["all", "demo-role"],
            //   icon: "fa-pencil-alt"
            // },
            {
              // ACT
              name: "greet.ACT",
              routerName: "ACTList",
              auths: ["all", "demo-role"],
              icon: "fa-pencil-alt"
            }
            // {
            //   // AP
            //   name: "greet.AP",
            //   path: `https://sat.ivy-way.com?token=${this.token}`,
            //   auths: ["all", "demo-role"],
            //   icon: "fa-pencil-alt"
            // },
            // {
            //   // TOEFL
            //   name: "greet.TOEFL",
            //   routerName: "ToeflList",
            //   auths: ["all", "demo-role"],
            //   icon: "fa-pencil-alt"
            // },
            // {
            //   // IELTS
            //   name: "greet.IELTS",
            //   routerName: "ToeflList",
            //   auths: ["all", "demo-role"],
            //   icon: "fa-pencil-alt"
            // }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    },
    sat() {
      return this.$route.query.sat;
    }
  },
  watch: {},

  async mounted() {
    // if(this.sat && this.sat === "sat") {
    //   window.location.href = `https://sat.ivy-way.com?token=${this.token}`;
    // }
    // if (!this.isLogin) {
    //   this.$router.push({ name: "LandingPage" });
    // }

    // try {
    //   if (!this.profile || this.profile.all_roles == null) {
    //     await this.getProfile();
    //   }
    // } catch (error) {
    //   this.$router.push({
    //     name: "Login"
    //   });
    // }
    // await this.fetchLineLoginAuthURL();
    this.dashboard[1].items.push({
      // SAT
      name: "greet.SAT",
      path: `https://sat.ivy-way.com?token=${this.token}`,
      auths: ["all", "demo-role"],
      icon: "fa-pencil-alt"
    });
  },

  methods: {
    hasPrimission({ items }) {
      return items.some(item => {
        return this.permissionAllow(item.auths);
      });
    },
    async fetchLineLoginAuthURL() {
      let url = await this.$router.resolve({
        name: "BindLineLogin"
      });
      let lineLoginAuthUrl = await lineLoginApis.getAuthURL({
        redirect_uri: window.location.origin + url.href
      });
      this.lineLoginAuthUrl = lineLoginAuthUrl.url;
    },
    permissionAllow(allowRoles) {
      if (_.includes(this.profile.all_roles, "demo-role")) {
        if (_.includes(allowRoles, "demo-role")) {
          if (_.includes(allowRoles, "all")) {
            return true;
          }

          return _.intersection(this.profile.all_roles, allowRoles).length > 1;
        }
        return false;
      }

      if (_.includes(allowRoles, "all")) {
        return true;
      }

      return _.intersection(this.profile.all_roles, allowRoles).length > 0;
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
.bread-header {
  margin: 0 50px;
}
.card-title {
  margin-left: 50px;
}
.card-item {
  margin-left: 25px;
  margin-right: 25px;
}
.font-icon {
  font-size: 100px;
}
.item {
  padding: 20px;
}
.center-row {
  height: 20vh;
}

.greet-color {
  color: #42a16a;
}

.greet-center {
  text-align: center;
}

a {
  text-decoration: none;
  color: #909399;
}

.card-body {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 5px;
  height: 180px;
  text-align: center;
}

.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
    margin-top: 5px;
  }
  .card-title {
    margin-left: 20px;
  }
  .card-item {
    margin: 0 10px;
  }
  .item {
    padding: 10px;
  }
  .font-icon {
    font-size: 70px;
  }
  .card-body {
    height: 160px;
  }
  .bread-header {
    margin: 0 20px;
  }
}
</style>
